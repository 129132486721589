import { ButtonWrapper } from "@/components/generic/form/buttonWrapper";
import Header from "@/components/generic/header";
import { useInterval } from "@/components/hooks/useInterval";
import Layout from "@/components/layout";
import TicketBox from "@/components/ticketBox";
import {
  TicketResponsesDocument,
  useCreateTicketResponseMutation,
  useGetTicketByIdQuery,
  useTicketResponsesQuery,
} from "@/generated";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { PageProps } from "gatsby";
import React, { useState } from "react";

type TicketInfoProps = PageProps;

const TicketInfo: React.FC<TicketInfoProps> = ({ params }) => {
  const ticket = useGetTicketByIdQuery({ variables: { ticketId: params.id } });
  const ticketResponses = useTicketResponsesQuery({ variables: { parentId: params.id } });
  const [addResponse, ticketResponseMutation] = useCreateTicketResponseMutation({ notifyOnNetworkStatusChange: true });
  const [message, setMessage] = useState("");

  const loading = ticket.loading || ticketResponses.loading || ticketResponseMutation.loading;

  const title =
    (ticket?.data?.authenticatedItem?.tickets && ticket?.data?.authenticatedItem?.tickets[0]?.subject) ||
    "Mijn bericht";

  useInterval(async () => await ticketResponses.refetch(), 5000);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await addResponse({
        variables: { data: { parent: { connect: { id: params.id } }, message } },
        update: async (cache, result) => {
          if (result.data.createTicketResponse) {
            cache.updateQuery({ query: TicketResponsesDocument, variables: { parentId: params.id } }, (data) => {
              console.log({ data, result });

              return {
                ...data,
                ticketResponses: [result?.data?.createTicketResponse, ...data?.ticketResponses],
              };
            });
          }
        },
      });

      setMessage("");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Layout title={title}>
      <Header>Ticket info</Header>
      <Box sx={{ display: "grid", gridTemplateColumns: { xs: "100%", lg: "50% 50%" } }}>
        <Box sx={{ alignSelf: "stretch", mb: 4 }}>
          <TicketBox
            loading={ticket.loading}
            ticket={ticket?.data?.authenticatedItem?.tickets[0]}
            footer={<Box sx={{ minHeight: 18 }} />}
          />
        </Box>
        <Box
          sx={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            gap: 4,
            width: "90%",
            marginRight: "auto",
            marginLeft: {
              xs: "auto",
              lg: 0,
            },
          }}
        >
          <form onSubmit={submit}>
            <TextField
              value={message}
              multiline
              fullWidth
              rows={4}
              label="Nieuw bericht"
              onChange={(e) => setMessage(e.currentTarget.value)}
              disabled={loading}
            />
            <ButtonWrapper>
              <Button variant="contained" type="submit" disabled={loading || !message}>
                Verstuur
              </Button>
              <IconButton
                disabled={loading}
                color="primary"
                onClick={async () => {
                  try {
                    await ticketResponses.refetch();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <CachedRoundedIcon />
              </IconButton>
            </ButtonWrapper>
          </form>

          {ticketResponses?.data?.ticketResponses?.map((response, i) => (
            <TextField
              key={response?.id}
              label={`${new Date(response?.createdAt).toLocaleString(undefined, {
                dateStyle: "short",
                timeStyle: "short",
              })} - ${response?.user?.displayName}:`}
              defaultValue={response?.message}
              multiline
              disabled={i > 0}
              inputProps={{ sx: { resize: "none", overflow: "auto" }, readOnly: true }}
            />
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default TicketInfo;
